exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-interviews-buildit-tsx": () => import("./../../../src/pages/interviews/buildit.tsx" /* webpackChunkName: "component---src-pages-interviews-buildit-tsx" */),
  "component---src-pages-interviews-codeorjp-tsx": () => import("./../../../src/pages/interviews/codeorjp.tsx" /* webpackChunkName: "component---src-pages-interviews-codeorjp-tsx" */),
  "component---src-pages-interviews-vook-tsx": () => import("./../../../src/pages/interviews/vook.tsx" /* webpackChunkName: "component---src-pages-interviews-vook-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */)
}

